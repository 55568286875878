
import { Component, Vue, Watch } from 'vue-property-decorator';
import { readGreetingMessages, readSiteCode, readGreetingSounds, readSites } from '@/store/main/getters';
import {
  dispatchGetGreetingMessages, dispatchUpdateGreetingMessage,
  dispatchCreateGreetingMessage, dispatchDeleteGreetingMessage,
  dispatchMoveUpGreetingMessage, dispatchMoveDownGreetingMessage,
  dispatchGetGreetingSounds,
} from '@/store/main/actions';
import { IGreetingMessage, IGreetingMessageCreate, IGreetingMessageUpdate } from '@/interfaces';
import { apiUrl } from '@/env';
import { commitAddNotification } from '@/store/main/mutations';
import moment from 'moment';


@Component
export default class Greeting extends Vue {
  public dialogCreateUpdate: boolean = false;
  public dialogDelete: boolean = false;
  public headers = [
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Message/Soundbite', value: 'sound', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
  public editedIndex: number = -1;
  public editedItem = {
    type: '',
    displayorder: 0,
    sound: '',
  };
  public defaultItem = {
    type: '',
    displayorder: 0,
    sound: '',
  };
  public validForm: boolean = false;
  public selectedDateMenu: boolean = false;

  get formTitle() {
    return this.editedIndex === -1 ? 'New Greeting Message' : 'Edit Greeting Message';
  }

  get today() {
    return moment().format('YYYY-MM-DD');
  }

  public computedDateFormatted(item: IGreetingMessage) {
    if (item.sound.startsWith('self.SayDate')) {
      item.sound = item.sound.split('"')[1];
    }
    return item.sound ? moment(item.sound, 'YYYY-MM-DD').format('L') : moment().format('L');
  }

  get messageTypes() {
    return [
      {value: 'foneandgo_auto', text: 'Automatic Message'},
      {value: 'circuit_optional', text: 'Circuit Optional'},
      {value: 'days_of_the_week', text: 'Days of the week'},
      {value: 'days_of_the_month', text: 'Days of the month'},
      {value: 'date', text: 'Date'},
      {value: 'optional', text: 'Optional'},
      {value: 'months', text: 'Months'},
      {value: 'hours_of_the_day', text: 'Hours of the day'},
      {value: 'minutes_of_the_hour', text: 'Minutes of the hour'},
      {value: 'movies', text: 'Movies'},
      {value: 'ratings', text: 'Ratings'},
      {value: 'temporary', text: 'Temporary'},
      {value: 'circuit_core', text: 'Circuit Core'},
    ];
  }

  get messages() {
    return readGreetingMessages(this.$store).filter((m) => m.type !== 'fone_go_welcome');
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  get sites() {
    return readSites(this.$store);
  }

  public async mounted() {
    await this.initialize();
  }

  public typeLabel(item: IGreetingMessage) {
    const messages = this.messageTypes;
    for (const i in messages) {
      if (messages[i].value === item.type) {
        return messages[i].text;
      }
    }
    return '';
  }

  public soundLabel(item: IGreetingMessage) {
    if (item.type === 'foneandgo_auto') {
      if (item.sound === 'self.upcoming_movies(i)') {
        return 'Upcoming Movies';
      } else if (item.sound === 'self.flagged_movies(i,"bigd")') {
        return 'Announce Big D';
      } else if (item.sound === 'self.midnight_movies(i)') {
        return 'Midnight Movies';
      }
    } else if (item.type === 'date') {
      return item.sound.split('"')[1];
    } else if (item.type === 'days_of_the_week') {
      return item.sound.charAt(0).toUpperCase() + item.sound.slice(1);
    }
    return item.sound;
  }

  public editItem(item: IGreetingMessage) {
    this.editedIndex = this.messages.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogCreateUpdate = true;
  }

  public deleteItem(item: IGreetingMessage) {
    this.editedIndex = this.messages.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public async deleteItemConfirm() {
    await dispatchDeleteGreetingMessage(this.$store, this.editedItem.displayorder);
    this.closeDelete();
  }

  public closeCreateUpdate() {
    this.dialogCreateUpdate = false;
    this.$validator.reset();
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$validator.reset();
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      if (this.editedIndex > -1) {
        const updatedGreetingMessage: IGreetingMessageUpdate = {
          sound: this.editedItem.sound,
          type: this.editedItem.type,
        };
        await dispatchUpdateGreetingMessage(
          this.$store, { displayorder: this.editedItem.displayorder, greetingMessage: updatedGreetingMessage },
        );
      } else {
        const createdGreetingMessage: IGreetingMessageCreate = {
          sound: this.editedItem.sound,
          type: this.editedItem.type,
        };
        await dispatchCreateGreetingMessage(this.$store, createdGreetingMessage);
      }
      this.closeCreateUpdate();
    }
  }

  public async moveUp(item: IGreetingMessage) {
    await dispatchMoveUpGreetingMessage(
      this.$store, { displayorder: item.displayorder },
    );
  }

  public async moveDown(item: IGreetingMessage) {
    await dispatchMoveDownGreetingMessage(
      this.$store, { displayorder: item.displayorder },
    );
  }

  public getSoundURL(item: IGreetingMessage) {
    if (this.siteCode) {
      return `${apiUrl}/api/v1/greeting-sounds/site/${this.siteCode}/type/${item.type}/${item.sound}.mp3`;
    }
  }

  public sounds(item: IGreetingMessage) {
    const gSounds = readGreetingSounds(this.$store);
    if (item.type === 'days_of_the_week') {
      return [
        {text: 'Monday', value: 'monday'},
        {text: 'Tuesday', value: 'tuesday'},
        {text: 'Wednesday', value: 'wednesday'},
        {text: 'Thursday', value: 'thursday'},
        {text: 'Friday', value: 'friday'},
        {text: 'Saturday', value: 'saturday'},
        {text: 'Sunday', value: 'sunday'},
      ];
    } else if (item.type === 'months') {
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
    } else if (item.type === 'hours_of_the_day') {
      return [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        'am',
        'midnight',
        'pm',
      ];
    } else if (item.type === 'foneandgo_auto') {
      if (item.sound === 'self.upcoming_movies(i)') {
        item.sound = 'upcoming_movies';
      } else if (item.sound === 'self.flagged_movies(i,"bigd")') {
        item.sound = 'flagged_movies';
      } else if (item.sound === 'self.midnight_movies(i)') {
        item.sound = 'midnight_movies';
      }
      return [
        {text: 'Upcoming Movies', value: 'upcoming_movies'},
        // {text: 'Announce Big D', value: 'flagged_movies'},
        {text: 'Midnight Movies', value: 'midnight_movies'},
      ];
    } else if (item.type !== '' && gSounds && item.type in gSounds) {
      return gSounds[item.type];
    }
    return [];
  }

  public typeUpdated() {
    this.editedItem.sound = '';
    if (this.editedItem.type === 'date') {
      this.editedItem.sound = this.today;
    }
  }

  @Watch('dialogCreateUpdate')
  private watchDialog(val: boolean) {
    if (!val) {
      this.closeCreateUpdate();
    }
  }

  @Watch('dialogDelete')
  private watchDialogDelete(val: boolean) {
    if (!val) {
      this.closeDelete();
    }
  }

  private async initialize() {
    if (this.siteCode) {
      await dispatchGetGreetingMessages(this.$store);
      await dispatchGetGreetingSounds(this.$store);
    }
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    await this.initialize();
  }

  @Watch('sites')
  private async watchSites() {
    this.informSharedLine();
  }

  private informSharedLine() {
    const sites = readSites(this.$store);
    const site = sites.find((s) => s.code === this.siteCode);
    if (site) {
      const filteredSites = sites.filter((s) => s.line && site.line && s.line.id === site.line.id);
      if (filteredSites.length > 1) {
        const payloadNotification = {
          content: 'There is more than one cinema on this number, please select any additional welcome messages for this site after it has been selected by the caller. This normally includes the name of the cinema, as well as any other optional messages that are specific to this cinema.',
          color: 'info',
          timeout: 50000,
        };
        commitAddNotification(this.$store, payloadNotification);
      }
    }
  }
}
